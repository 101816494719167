import { NACEOptions } from "../../MuiDataGridTable/constants";

export function naceCodeToText(code: string) {
    const option = NACEOptions.find((c) => c.nace === code.slice(0, 5));
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb" || locale === "no" || locale === "nn") {
        return `${option.nace}: ${option.name_no}`;
    }
    return `${option.nace}: ${option.name_en}`;
}
