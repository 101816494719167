import { Lock } from "@ignite-analytics/icons";
import { Chip, ChipProps, Stack, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormattedMessage } from "react-intl";

export const DisabledField = styled("div")(() => ({
    display: "flex",
    alignContent: "center",
    width: "100%",
    height: "100%",
    padding: "0 8px",
    alignItems: "center",
    boxSizing: "border-box",
}));

type RiskValues = "low" | "very_low" | "medium" | "high" | "very_high";

const getRiskChipProps = (value: RiskValues): { color: ChipProps["color"]; label: React.ReactNode } => {
    switch (value) {
        case "low":
            return {
                color: "success",
                label: <FormattedMessage defaultMessage="Low" description="'Low' risk chip label" />,
            };
        case "very_low":
            return {
                color: "success",
                label: <FormattedMessage defaultMessage="Very Low" description="'Very Low' risk chip label" />,
            };
        case "medium":
            return {
                color: "warning",
                label: <FormattedMessage defaultMessage="Medium" description="'Medium' risk chip label" />,
            };
        case "high":
            return {
                color: "error",
                label: <FormattedMessage defaultMessage="High" description="'High' risk chip label" />,
            };
        case "very_high":
            return {
                color: "error",
                label: <FormattedMessage defaultMessage="Very High" description="'Very High' risk chip label" />,
            };
        default:
            return {
                color: "error",
                label: <FormattedMessage defaultMessage="Unknown" description="'Unknown' risk chip label" />,
            };
    }
};

interface RiskFieldProps {
    value: RiskValues;
}

export const TableRiskField: React.FC<RiskFieldProps> = ({ value }) => {
    if (!value || value === undefined) return null;

    const { color, label } = getRiskChipProps(value);
    return (
        <Tooltip placement="top" title={<Lock fontSize="small" />}>
            <Stack width="100%">
                <DisabledField>
                    <Chip size="small" color={color} label={label} sx={{ textTransform: "capitalize" }} />
                </DisabledField>
            </Stack>
        </Tooltip>
    );
};
