import { X } from "@ignite-analytics/icons";
import { Button, Chip, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
    onClose: VoidFunction;
    setStep: React.Dispatch<React.SetStateAction<"1" | "2" | "3">>;
}

export const HowWeCalculateSocialRisk: React.FC<Props> = ({ onClose, setStep }) => {
    const { locale } = useIntl();
    const helpCenterUrl = `https://help-eu.igniteprocurement.com/${
        locale.startsWith("nb") ? "nb" : "en"
    }/articles/71291-evaluering-av-sosialrisiko-for-leverandorer-i-ignite`;
    const { formatMessage } = useIntl();
    return (
        <>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <FormattedMessage defaultMessage="Social risk evaluation" description="Social risk modal title" />
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.gray.dark,
                            position: "absolute",
                            top: 4,
                            right: 4,
                        }}
                    >
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2} paddingTop={1}>
                    <Typography
                        variant="textSm"
                        justifyContent="center"
                        color={(theme) => theme.palette.text["textHelper"]}
                    >
                        <FormattedMessage
                            defaultMessage="Ignite's social risk evaluation combines industry risk and country risk.
                            After completing these steps, we will add the following columns to your suppliers:"
                            description="Social risk explanation"
                        />
                    </Typography>
                    <Stack direction="row" spacing={1}>
                        <Chip
                            label={formatMessage({
                                defaultMessage: "Nace-code",
                                description: "Nace-code bullet point",
                            })}
                            color="primary"
                        />
                        <Chip
                            label={formatMessage({
                                defaultMessage: "Country risk",
                                description: "Country risk bullet point",
                            })}
                            color="primary"
                        />
                        <Chip
                            label={formatMessage({
                                defaultMessage: "Industry risk",
                                description: "Industry risk bullet point",
                            })}
                            color="primary"
                        />
                        <Chip
                            label={formatMessage({
                                defaultMessage: "Social risk",
                                description: "Social risk bullet point",
                            })}
                            color="primary"
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" alignItems="center" width="100%" justifyContent="space-between">
                    <Button target="_blank" href={helpCenterUrl} variant="text" size="medium" color="inherit">
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Learn more" description="Back button" />
                        </Typography>
                    </Button>
                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Button variant="outlined" onClick={onClose} size="medium" color="inherit">
                            <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setStep("2");
                            }}
                            size="medium"
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Next" description="Next button" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </>
    );
};

export default HowWeCalculateSocialRisk;
