import { useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Chip, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { AddSupplierEnrichment_SupplierTableColumnFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

const startSocialRiskAssessmentDocument = graphql(`
    mutation startSocialRiskAssessment($input: StartSocialRiskAssessmentInput!) {
        startSocialRiskAssessment(input: $input) {
            ok
        }
    }
`);

graphql(`
    fragment AddSupplierEnrichment_RiskColumnConfig on GetRiskColumnConfigResponse {
        id
        socialRiskColumn
        industryRiskColumn
        geographyRiskColumn
        naceColumn
        nameColumn
        countryColumn
        orgNumberColumn
    }

    fragment AddSupplierEnrichment_SupplierTableColumn on SupplierTableColumn {
        id
        name
    }
`);

interface Props {
    onClose: VoidFunction;
    columns?: AddSupplierEnrichment_SupplierTableColumnFragment[];
    setStep: React.Dispatch<React.SetStateAction<"1" | "2" | "3">>;
    handlePolling: () => void;
}

export const AddSupplierEnrichment: React.FC<Props> = ({ onClose, columns, setStep, handlePolling }) => {
    const [startSocialRiskAssessment] = useMutation(startSocialRiskAssessmentDocument, {
        variables: {
            input: {
                countryColumnId: "country",
                nameColumnId: "name",
                orgNumberColumnId: "reg_nr",
            },
        },
    });

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const handleSubmit = () => {
        track("Supplier Table: Added Social Risk");
        startSocialRiskAssessment({
            onCompleted: () => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Social risk columns have been configured",
                        description: "Alert message when risk columns were successfully configured",
                    }),
                    title: formatMessage({
                        defaultMessage: "Success",
                        description: "Alert title when risk columns are successfully configured",
                    }),
                    severity: "success",
                });
                handlePolling();
                setStep("3");
            },
            onError: (error) => {
                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "An error occurred while configuring the risk columns: {error}",
                            description: "Alert message when risk configuration fails",
                        },
                        {
                            error: error.message,
                        }
                    ),
                    title: formatMessage({
                        defaultMessage: "Error",
                        description: "Alert title when risk configuration has an error",
                    }),
                    severity: "error",
                });
            },
        });
    };

    return (
        <>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Typography variant="textLg">
                        <FormattedMessage
                            defaultMessage="Add supplier enrichment"
                            description="Add supplier enrichment title"
                        />
                    </Typography>
                    <IconButton
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.gray.dark,
                            position: "absolute",
                            top: 4,
                            right: 4,
                        }}
                    >
                        <X />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={4}>
                    <Typography variant="textSm" color={(theme) => theme.palette.text["textHelper"]} paddingTop={1}>
                        <FormattedMessage
                            defaultMessage="To evaluate social risk we use the NACE-code of a company. Please make sure that the columns that represent name, company reg. number and country has been properly set up. We will use these to automatically fetch the NACE where available."
                            description="bla"
                        />
                    </Typography>
                    <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="textSm" color={(theme) => theme.palette.text["textHelper"]}>
                                <FormattedMessage defaultMessage="Supplier name" />
                            </Typography>
                            <Chip
                                color="primary"
                                label={
                                    columns?.find((c) => c.id === "name")?.name ??
                                    formatMessage({ defaultMessage: "Not set" })
                                }
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="textSm" color={(theme) => theme.palette.text["textHelper"]}>
                                <FormattedMessage defaultMessage="Country" />
                            </Typography>
                            <Chip
                                color="primary"
                                label={
                                    columns?.find((c) => c.id === "country")?.name ??
                                    formatMessage({ defaultMessage: "Not set" })
                                }
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="textSm" color={(theme) => theme.palette.text["textHelper"]}>
                                <FormattedMessage defaultMessage="Company registration number" />
                            </Typography>
                            <Chip
                                color="primary"
                                label={
                                    columns?.find((c) => c.id === "reg_nr")?.name ??
                                    formatMessage({ defaultMessage: "Not set" })
                                }
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack spacing={2} direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} justifyContent="end">
                        <Button variant="outlined" onClick={onClose} size="medium" color="inherit">
                            <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleSubmit();
                            }}
                            size="medium"
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Next" description="add button" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </>
    );
};

export default AddSupplierEnrichment;
