import { ArrowRight } from "@ignite-analytics/icons";
import { formatValue } from "@ignite-analytics/locale";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import WarningIcon from "@/assets/WarningIcon.svg?react";
import { GetRiskColumnConfigResponse } from "@/gql/graphql";

import { FilterInput } from "../../../InlineFilter/types";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

interface TotalHighRiskSuppliersProps {
    totalHighRiskSuppliers?: number;
    totalHighRiskSuppliersLoading: boolean;
    totalSuppliers?: number;
    totalSuppliersLoading: boolean;
    riskColumnConfig?: Omit<GetRiskColumnConfigResponse, "id">;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}

export const TotalHighRiskSuppliers: React.FC<TotalHighRiskSuppliersProps> = ({
    totalHighRiskSuppliers,
    totalSuppliers,
    riskColumnConfig,
    onNavigate,
    totalSuppliersLoading,
    totalHighRiskSuppliersLoading,
}) => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const percentageHighRisk =
        totalHighRiskSuppliers &&
        totalSuppliers &&
        formatValue((totalHighRiskSuppliers / totalSuppliers) * 100, 2, undefined, true);

    const handleOnClick = () => {
        if (!riskColumnConfig) return;
        const filter: FilterInput[] = [
            {
                type: "include",
                column_id: riskColumnConfig.socialRiskColumn,
                include_exclude_values: ["high"],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: false,
            },
        ];
        onNavigate(filter, Object.values(riskColumnConfig), "TotalHighRiskSuppliers");
    };

    const errorState = (!totalSuppliers || !riskColumnConfig) && (
        <Stack justifyContent="space-between" height="100%" spacing={1.5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="textSm" color="text.textHelper">
                    <FormattedMessage
                        defaultMessage="Suppliers with high social risk"
                        description="Suppliers with high risk header"
                    />
                </Typography>
                <WarningIcon />
            </Stack>
            {!riskColumnConfig ? (
                <Stack justifyContent="space-between" height="100%">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="Please configure the social risk column in the settings"
                            description="Please configure social risk column"
                        />
                    </Typography>
                    <Stack direction="row">
                        <Button
                            color="secondary"
                            onClick={() => navigate("/suppliers/overview")}
                            endIcon={<ArrowRight fontSize="small" />}
                        >
                            <FormattedMessage
                                defaultMessage="Run social risk"
                                description="Button to navigate to suppliers overview to run social risk"
                            />
                        </Button>
                    </Stack>
                </Stack>
            ) : (
                <Stack justifyContent="space-between" height="100%">
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage="We need to know which data you want us to use to craft this insight."
                            description="Please configure social risk column"
                        />
                    </Typography>
                    <Stack direction="row">
                        <Button
                            color="secondary"
                            onClick={() => navigate("/suppliers/overview")}
                            endIcon={<ArrowRight fontSize="small" />}
                            size="xsmall"
                        >
                            <FormattedMessage
                                defaultMessage="Add data"
                                description="Button to navigate to suppliers overview to run social risk"
                            />
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );

    return (
        <InsightBox loading={totalSuppliersLoading || totalHighRiskSuppliersLoading}>
            {!errorState ? (
                <Stack justifyContent="space-between" height="100%">
                    <Stack spacing={1.5}>
                        <Typography variant="textSm" color="text.textHelper">
                            <FormattedMessage
                                defaultMessage="Suppliers with high social risk"
                                description="Suppliers with high risk header"
                            />
                        </Typography>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="displayMd" fontWeight="medium">
                                {totalHighRiskSuppliers}
                            </Typography>
                            <Box>
                                <Chip color="primary" label={`${percentageHighRisk ?? ""}% of all suppliers`} />
                            </Box>
                        </Stack>
                    </Stack>
                    <ViewButton
                        buttonText={formatMessage({
                            defaultMessage: "View Suppliers",
                            description: "View suppliers button",
                        })}
                        onClick={handleOnClick}
                    />
                </Stack>
            ) : (
                errorState
            )}
        </InsightBox>
    );
};
