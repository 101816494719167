/* eslint-disable no-alert */
const BASE_URL = process.env.REACT_APP_PUBLIC_URL;

interface WhoAmIResponse {
    identity: {
        id: string;
        traits: {
            email: string;
        };
        // eslint-disable-next-line camelcase
        metadata_public: {
            tenant?: string;
        };
    };
    error?: {
        id: string;
    };
}

async function setupPeriodicJWTFetch(intervalSeconds: number, tenant: string) {
    const jwtGenerateURL = `${BASE_URL}/tokxchange/api/v2/generate`;
    async function fetchToken() {
        const res = await fetch(jwtGenerateURL, {
            method: "POST",
            headers: {
                "x-tenant-id": tenant,
            },
        });
        if (res.ok) {
            const token = await res.json();
            localStorage.setItem("token", token);
        }
        return res;
    }

    const timeout = setInterval(fetchToken, intervalSeconds * 1000);
    await fetchToken();
    return () => clearInterval(timeout);
}
export async function setupProxyAuth() {
    const setupURL = `${BASE_URL}/ory/self-service/login/browser`;
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();

    if (setupData.error?.id !== "session_already_available") {
        const username = process.env.REACT_APP_DEFAULT_USER || prompt("Please enter your username");
        const password = process.env.REACT_APP_DEFAULT_PASSWORD || prompt("Please enter your password");

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const csrfToken = setupData.ui.nodes.find((node: any) => node?.attributes?.name === "csrf_token").attributes
            .value;
        const { action } = setupData.ui;
        const flowId = action.split("login?flow=").pop();

        const loginURL = `${BASE_URL}/ory/self-service/login?flow=${flowId}`;

        await fetch(loginURL, {
            method: "POST",
            credentials: "include",
            headers: { accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                csrf_token: csrfToken,
                method: "password",
                identifier: username,
                password,
                password_identifier: username,
            }),
        });
    }

    const sessionURL = `${BASE_URL}/ory/sessions/whoami`;
    const sessionResponse = await fetch(sessionURL, { headers: { accept: "application/json" }, method: "GET" });
    const sessionData: WhoAmIResponse = await sessionResponse.json();

    const sessionContext = {
        id: sessionData.identity.id,
        tenant: sessionData.identity?.metadata_public?.tenant ?? "", // empty tenant happens quite often, should be handled
        email: sessionData.identity.traits.email,
    };

    localStorage.setItem("tenant", sessionContext.tenant);
    await setupPeriodicJWTFetch(60, sessionContext.tenant);
    return sessionContext;
}
