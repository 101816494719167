import { useMutation } from "@apollo/client";
import { Calendar, File, ChartNetwork, Calculator, Menu } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ColumnType, EditColumn_SupplierTableColumnFragment } from "@/gql/graphql";
import { useAlert } from "@/providers/Alerts";

import { TableActions } from "../constants";

import { ChoiceList } from "./choiceList";

const updateSupplierTableColumnDocument = graphql(`
    mutation EditColumn_UpdateSupplierTableColumn($input: UpdateSupplierTableColumnInput!) {
        updateSupplierTableColumn(input: $input) {
            column {
                id
                name
            }
        }
    }
`);

graphql(`
    fragment EditColumn_SupplierTableColumn on SupplierTableColumn {
        id
        type
        name
    }
`);

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<TableActions | false>>;
    column: EditColumn_SupplierTableColumnFragment;
    isRiskColumn: boolean;
}

export const EditColumn: React.FC<Props> = ({ open, setOpen, column, isRiskColumn }) => {
    type RelevantColumnTypes = "TEXT" | "NUMBER" | "DATE" | "SELECT" | "AGGREGATION" | "SPEND";
    const type = column?.type as Extract<ColumnType, RelevantColumnTypes>;
    const options: Record<
        Extract<ColumnType, RelevantColumnTypes>,
        {
            icon: React.ReactNode;
            label: React.ReactNode;
        }
    > = {
        ["TEXT"]: {
            icon: <File fontSize="small" />,
            label: <FormattedMessage defaultMessage="Text" description="'Text' column type option" />,
        },
        ["NUMBER"]: {
            icon: <Calculator fontSize="small" />,
            label: <FormattedMessage defaultMessage="Number" description="'Number' column type option" />,
        },
        ["DATE"]: {
            icon: <Calendar fontSize="small" />,
            label: <FormattedMessage defaultMessage="Date" description="'Date' column type option" />,
        },
        ["SELECT"]: {
            icon: <Menu fontSize="small" />,
            label: <FormattedMessage defaultMessage="Select" description="'Select' column type option" />,
        },
        ["AGGREGATION"]: {
            icon: <ChartNetwork fontSize="small" />,
            label: <FormattedMessage defaultMessage="Aggregation" description="'Aggregation' column type option" />,
        },
        ["SPEND"]: {
            icon: <Menu fontSize="small" />,
            label: <FormattedMessage defaultMessage="Spend" description="'Spend' column type option" />,
        },
    };
    const [name, setName] = React.useState<string>(column.name);

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [updateColumnNameMutation] = useMutation(updateSupplierTableColumnDocument);

    const handleClose = () => {
        setOpen(false);
    };

    const handleUpdateColumn = () => {
        if (name == column.name) {
            setOpen(false);
            return;
        }
        updateColumnNameMutation({
            variables: {
                input: {
                    id: column.id,
                    name: name,
                },
            },
            onCompleted: () => {
                track("Supplier Table: Edit Column: Renamed Column");
                handleClose();
            },
            onError: () => {
                alertUser({
                    value: formatMessage({
                        defaultMessage: "Error updating column name",
                        description: "Alert message for error when updating column name",
                    }),
                    severity: "error",
                });
            },
        });
    };
    const icon = type ? options[type]?.icon : undefined;
    const label = type ? options[type]?.label : undefined;
    return (
        <form>
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
                <DialogTitle>
                    <FormattedMessage defaultMessage="Edit column" description="Edit column title" />
                </DialogTitle>
                <DialogContent>
                    <Stack paddingTop={1}>
                        <FormControl>
                            <InputLabel id="select-type" size="small">
                                <FormattedMessage defaultMessage="Type" description="Column type label" />
                            </InputLabel>
                            <Select
                                name="type"
                                value={label}
                                label={<FormattedMessage defaultMessage="Type" description="Column type label" />}
                                size="small"
                                fullWidth
                                startAdornment={icon && <InputAdornment position="start">{icon}</InputAdornment>}
                                renderValue={() => label}
                                disabled
                            >
                                {Object.entries(options).map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                        <ListItemIcon>{value.icon}</ListItemIcon>
                                        {value.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ mt: 2 }}
                            id="name-input"
                            name="name"
                            label={
                                <FormattedMessage defaultMessage="Title" description="Add column title placeholder" />
                            }
                            type="text"
                            value={name}
                            size="small"
                            onChange={(e) => setName(e.target.value)}
                        />
                        {type == "SELECT" && !isRiskColumn && <ChoiceList column={column} />}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Stack spacing={2} direction="row">
                        <Button onClick={handleClose} variant="text" color="secondary">
                            <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                        </Button>
                        <Button onClick={handleUpdateColumn}>
                            <FormattedMessage defaultMessage="Done" description="Done button" />
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </form>
    );
};
