import { useMutation } from "@apollo/client";
import { ShapeIcon } from "@ignite-analytics/components";
import {
    Calendar,
    Check,
    ChevronDown,
    ChevronRight,
    ChevronUp,
    ClipboardList,
    GridPlus,
    LocationPin,
    Numberlist,
    Search,
    Suitcase,
    Text,
    X,
} from "@ignite-analytics/icons";
import {
    Alert,
    Autocomplete,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Popover,
    Snackbar,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { BulkActionsButton_SupplierTableColumnFragment } from "@/gql/graphql";
import { track } from "@/lib/track";
import { useAlert } from "@/providers/Alerts";

import { EllipticTypography } from "../../../../EllipticTypography";
import { NoPermissionTooltip } from "../../../../NoPermissionTooltip";

import { CountrySelect } from "./CountrySelect";
import { IndustryAutocomplete } from "./IndustrySelect";

interface ColumnUpdate {
    columnID: string;
    valueJSON: string;
    fieldType: "country" | "industry" | "text" | "number" | "date" | "select";
}

type SubmitUpdate = (update: ColumnUpdate) => Promise<void>;

graphql(`
    fragment BulkActionsButton_SupplierTableColumn on SupplierTableColumn {
        id
        name
        type
        typeOptions {
            ... on SelectOptions {
                __typename
                choices
            }
        }
    }
`);

const bulkUpdateSupplierFieldMutation = graphql(`
    mutation BulkActionsButton_BulkUpdateSupplierField($input: BulkUpdateSupplierFieldInput!) {
        bulkUpdateSupplierField(input: $input) {
            suppliers {
                id
            }
        }
    }
`);

const ORDERED_KNOWN_FIELDS = ["name", "country", "reg_nr"];

const sortByName = <T extends { name: string }>(items: T[]) => {
    items.sort((a, b) => {
        return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
    });
    return items;
};

const ColumnIcon: React.FC<{ column: BulkActionsButton_SupplierTableColumnFragment }> = ({ column }) => {
    if (column.id === "country") {
        return <LocationPin fontSize="small" />;
    }
    switch (column.type) {
        case "NACE":
            return <Suitcase fontSize="small" />;
        case "NUMBER":
            return <Numberlist fontSize="small" />;
        case "SELECT":
            return <ClipboardList fontSize="small" />;
        case "TEXT":
            return <Text fontSize="small" />;
        case "DATE":
            return <Calendar fontSize="small" />;
        default:
            return null;
    }
};

const EditPopoverContent: React.FC<{
    column: BulkActionsButton_SupplierTableColumnFragment;
    submitUpdate: SubmitUpdate;
}> = ({ column, submitUpdate }) => {
    const { formatMessage } = useIntl();
    if (column.id === "country") {
        return (
            <CountrySelect
                onChange={(newIndustry) => {
                    submitUpdate({
                        columnID: column.id,
                        valueJSON: JSON.stringify(newIndustry),
                        fieldType: "country",
                    });
                }}
            />
        );
    }
    switch (column.type) {
        case "NACE":
            return (
                <IndustryAutocomplete
                    onChange={(newIndustry) => {
                        submitUpdate({
                            columnID: column.id,
                            valueJSON: JSON.stringify(newIndustry),
                            fieldType: "industry",
                        });
                    }}
                />
            );
        case "NUMBER":
            return (
                <TextField
                    type="number"
                    placeholder={formatMessage({
                        defaultMessage: "Set a new value",
                        description: "Placeholder for number input field in bulk edit popover",
                    })}
                    aria-label={formatMessage({
                        defaultMessage: "Set a new value",
                        description: "ARIA label for number input field in bulk edit popover",
                    })}
                    InputProps={{
                        onKeyDown: (event) => {
                            if (event.key === "Enter") {
                                const value = event.currentTarget.value;
                                const numberValue = value === "" ? null : Number(value);
                                submitUpdate({
                                    columnID: column.id,
                                    valueJSON: JSON.stringify(numberValue),
                                    fieldType: "industry",
                                });
                            }
                            // Don't propagate the event to the menu -> focuses a menu item starting with that letter
                            event.stopPropagation();
                        },
                    }}
                />
            );
        case "SELECT":
            if (column.typeOptions?.__typename !== "SelectOptions") {
                return null;
            }
            return (
                <Autocomplete
                    sx={{ minWidth: 250 }}
                    size="small"
                    options={column.typeOptions.choices}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            aria-label={formatMessage({
                                defaultMessage: "Select a new choice",
                                description: "ARIA label for select input field in bulk edit popover",
                            })}
                            placeholder={formatMessage({
                                defaultMessage: "Select a new choice",
                                description: "Placeholder for select input field in bulk edit popover",
                            })}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                    onKeyDown={(event) => {
                        // Don't propagate the event to the menu -> focuses a menu item starting with that letter
                        event.stopPropagation();
                    }}
                    onChange={(_, value) => {
                        if (!value) {
                            submitUpdate({
                                columnID: column.id,
                                valueJSON: JSON.stringify(null),
                                fieldType: "select",
                            });
                            return;
                        }
                        submitUpdate({
                            columnID: column.id,
                            valueJSON: JSON.stringify(value),
                            fieldType: "select",
                        });
                    }}
                />
            );
        case "TEXT":
            return (
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Text />
                            </InputAdornment>
                        ),
                    }}
                    aria-label={formatMessage({
                        defaultMessage: "Set a new value",
                        description: "ARIA label for text input field in bulk edit popover",
                    })}
                    placeholder={formatMessage({
                        defaultMessage: "Set a new value",
                        description: "Placeholder for text input field in bulk edit popover",
                    })}
                    inputProps={{
                        onKeyDown: (event) => {
                            // Don't propagate the event to the menu -> focuses a menu item starting with that letter
                            event.stopPropagation();

                            if (event.key === "Enter") {
                                submitUpdate({
                                    columnID: column.id,
                                    valueJSON: JSON.stringify(event.currentTarget.value),
                                    fieldType: "text",
                                });
                            }
                        },
                    }}
                />
            );
        case "DATE":
            return (
                <DesktopDatePicker
                    timezone="UTC"
                    onAccept={(date: dayjs.Dayjs | null) => {
                        if (!date || !date.isValid()) {
                            submitUpdate({
                                columnID: column.id,
                                valueJSON: JSON.stringify(null),
                                fieldType: "date",
                            });
                            return;
                        }

                        submitUpdate({
                            columnID: column.id,
                            valueJSON: date.toISOString().split("T")[0],
                            fieldType: "date",
                        });
                    }}
                />
            );
        default:
            return null;
    }
};

const ColumnMenuItem: React.FC<{
    column: BulkActionsButton_SupplierTableColumnFragment;
    submitUpdate: SubmitUpdate;
}> = ({ column, submitUpdate }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const submitUpdateAndClose = React.useCallback(
        async (update: ColumnUpdate) => {
            handleClose();
            await submitUpdate(update);
        },
        [submitUpdate]
    );

    return (
        <React.Fragment>
            <MenuItem key={column.id} onClick={handleClick} selected={open}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Stack direction="row" alignItems="center" gap={1} minWidth={0}>
                        <ColumnIcon column={column} />
                        <EllipticTypography variant="textSm" value={column.name} />
                    </Stack>
                    <ChevronRight />
                </Stack>
            </MenuItem>

            {open && (
                // Unmount on close to drop the state in the edit popover
                <Popover
                    open
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Stack paddingX={2} paddingY={1.25}>
                        <EditPopoverContent column={column} submitUpdate={submitUpdateAndClose} />
                    </Stack>
                </Popover>
            )}
        </React.Fragment>
    );
};

export const BulkActionsButton: React.FC<{
    isEditor: boolean;
    selectedSuppliers: string[];
    columns: BulkActionsButton_SupplierTableColumnFragment[];
    onSelect: (ids: string[]) => void;
    refetchSuppliers: () => void;
}> = ({ isEditor, selectedSuppliers, columns, onSelect, refetchSuppliers }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [lastSelection, setLastSelection] = React.useState<string[]>([]);
    const [successAlertOpen, setSuccessAlertOpen] = React.useState(false);

    // True if we should show the "completed" checkmark
    // Should be unset after a while
    const [showCompleted, setShowCompleted] = React.useState(false);

    const [bulkUpdateSupplierField, { loading: mutationLoading }] = useMutation(bulkUpdateSupplierFieldMutation, {
        onError: (error) => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to update suppliers",
                    description: "Alert message when bulk update supplier field fails",
                }),
                severity: "error",
            });
            Sentry.captureException(error, {
                tags: { app: "suppliers-app", message: "Failed to bulk update supplier field" },
            });
        },
        onCompleted: () => {
            // Assume only one request is in flight at a time
            setSuccessAlertOpen(true);
            onSelect([]);
            setShowCompleted(true);
            setTimeout(() => {
                setShowCompleted(false);
            }, 1000);

            // Refetch to see the changes, and potentially reapply filters
            refetchSuppliers();
        },
    });

    const [nameSearch, setNameSearch] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const searchFieldRef = React.useRef<HTMLInputElement | null>(null);

    const handleClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        [setAnchorEl]
    );
    const handleClose = React.useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const submitUpdate = React.useCallback(
        async ({ columnID, valueJSON, fieldType }: ColumnUpdate) => {
            setLastSelection(selectedSuppliers);
            handleClose();
            track("Supplier Table: Bulk updated field", {
                numberOfSuppliers: selectedSuppliers.length,
                fieldId: columnID,
                isCustomField: columnID.startsWith("custom_"),
                fieldType,
                isBlank: valueJSON === "null" || valueJSON === "",
            });
            await bulkUpdateSupplierField({
                variables: {
                    input: {
                        supplierIDs: selectedSuppliers,
                        fieldId: columnID,
                        fieldData: valueJSON,
                    },
                },
            });
            return;
        },
        [bulkUpdateSupplierField, selectedSuppliers, handleClose, setLastSelection]
    );

    const editableColumns = columns.filter((column) => {
        if (column.id === "name" || column.id === "reg_nr") {
            // Does not make sense to bulk edit name or reg_nr, I think
            return false;
        }
        if (!["SELECT", "TEXT", "NUMBER", "DATE", "NACE"].includes(column.type)) {
            // Only allow these types
            return false;
        }
        return true;
    });

    const searchedForColumns = editableColumns.filter((column) =>
        column.name.toLowerCase().includes(nameSearch.toLowerCase())
    );

    const standardColumns = searchedForColumns.filter(
        (column) => ORDERED_KNOWN_FIELDS.includes(column.id) || column.type === "NACE"
    );
    standardColumns.sort((a, b) => {
        // Nace on bottom
        if (a.type === "NACE") {
            return 1;
        }
        if (b.type === "NACE") {
            return -1;
        }

        return ORDERED_KNOWN_FIELDS.indexOf(a.id) - ORDERED_KNOWN_FIELDS.indexOf(b.id);
    });

    const nonStandardColumns = sortByName(
        searchedForColumns.filter((column) => !standardColumns.includes(column) && column.id.startsWith("custom_"))
    );
    const numberColumns = nonStandardColumns.filter((column) => column.type === "NUMBER");
    const textColumns = nonStandardColumns.filter((column) => column.type === "TEXT");
    const selectColumns = nonStandardColumns.filter((column) => column.type === "SELECT");
    const dateColumns = nonStandardColumns.filter((column) => column.type === "DATE");

    const columnMenuItems: React.ReactNode[] = [];
    const addColumnMenuItems = (columns: BulkActionsButton_SupplierTableColumnFragment[]) => {
        columns.forEach((column) => {
            columnMenuItems.push(<ColumnMenuItem key={column.id} column={column} submitUpdate={submitUpdate} />);
        });
    };

    if (standardColumns.length > 0) {
        columnMenuItems.push(<Divider key="before-standard" />);
        addColumnMenuItems(standardColumns);
    }

    if (numberColumns.length > 0) {
        columnMenuItems.push(<Divider key="before-number" />);
        addColumnMenuItems(numberColumns);
    }

    if (textColumns.length > 0) {
        columnMenuItems.push(<Divider key="before-text" />);
        addColumnMenuItems(textColumns);
    }

    if (selectColumns.length > 0) {
        columnMenuItems.push(<Divider key="before-select" />);
        addColumnMenuItems(selectColumns);
    }

    if (dateColumns.length > 0) {
        columnMenuItems.push(<Divider key="before-date" />);
        addColumnMenuItems(dateColumns);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {mutationLoading && <CircularProgress size={20} />}
            {!mutationLoading && showCompleted && <Check color="success" />}
            {!showCompleted && !mutationLoading && (
                <NoPermissionTooltip hasPermission={isEditor}>
                    <Tooltip
                        title={
                            mutationLoading ? (
                                <Stack direction="row" gap={1} alignItems="center">
                                    <CircularProgress size={20} />
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            defaultMessage="Updating suppliers"
                                            description="Updating suppliers bulk edit button tooltip"
                                        />
                                    </Typography>
                                </Stack>
                            ) : selectedSuppliers.length === 0 ? (
                                <FormattedMessage
                                    defaultMessage="Select one or more suppliers to bulk edit data"
                                    description="No suppliers selected bulk edit button tooltip"
                                />
                            ) : undefined
                        }
                        placement="top"
                    >
                        {/* div to enable tooltip while the button is disabled */}
                        <div>
                            <Button
                                color="secondary"
                                onClick={handleClick}
                                size="small"
                                disabled={!isEditor || selectedSuppliers.length === 0 || mutationLoading}
                                endIcon={open ? <ChevronUp /> : <ChevronDown />}
                            >
                                <FormattedMessage defaultMessage="Bulk edit" description="Bulk edit button title" />
                            </Button>
                        </div>
                    </Tooltip>
                </NoPermissionTooltip>
            )}
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        sx: {
                            width: 350,
                        },
                    },
                }}
                sx={{ maxHeight: 600 }}
            >
                <MenuItem onClick={() => searchFieldRef.current?.focus()}>
                    <TextField
                        inputRef={searchFieldRef}
                        onKeyDown={(event) => {
                            if (["ArrowUp", "ArrowDown"].includes(event.key)) {
                                return;
                            }
                            // Don't propagate regular keystrokes to the menu -> focuses a menu item starting with that letter
                            event.stopPropagation();
                        }}
                        fullWidth
                        aria-label={formatMessage({
                            defaultMessage: "Search for a field",
                            description: "Field selection search input ARIA label",
                        })}
                        placeholder={formatMessage({
                            defaultMessage: "Search for a field",
                            description: "Field selection search input placeholder",
                        })}
                        value={nameSearch}
                        onChange={(event) => {
                            setNameSearch(event.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                            endAdornment: nameSearch ? (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={() => setNameSearch("")}>
                                        <X />
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined,
                        }}
                    />
                </MenuItem>
                {columnMenuItems.length > 0 ? (
                    columnMenuItems
                ) : (
                    <React.Fragment>
                        <Divider />
                        <Stack
                            direction="column"
                            padding={1}
                            alignItems="center"
                            width="100%"
                            justifyContent="center"
                            paddingX={2}
                            paddingY={6}
                            gap={3}
                            minHeight={340}
                            textAlign="center"
                        >
                            <ShapeIcon variant="default" color="gray" size="medium">
                                <GridPlus color="accent" />
                            </ShapeIcon>
                            <Typography variant="textSm" color="text.textHelper">
                                <FormattedMessage
                                    defaultMessage="Nothing turned up. Try checking for typos, or make sure the field you’re searching for is editable."
                                    description="No fields found in bulk edit menu"
                                />
                            </Typography>
                        </Stack>
                    </React.Fragment>
                )}
            </Menu>

            <Snackbar
                open={successAlertOpen}
                onClose={() => setSuccessAlertOpen(false)}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert
                    severity="success"
                    action={
                        <IconButton size="2xsmall" onClick={() => setSuccessAlertOpen(false)} color="success">
                            <X />
                        </IconButton>
                    }
                    sx={{ width: "100%" }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between" gap={2}>
                        <Typography fontWeight={500} variant="textSm">
                            <FormattedMessage
                                defaultMessage="{count, plural, one {# supplier has} other {# suppliers have}} been edited and removed from the selection"
                                description="Bulk update success alert message"
                                values={{ count: lastSelection.length }}
                            />
                        </Typography>
                        <Button
                            size="2xsmall"
                            color="success"
                            onClick={() => {
                                onSelect(lastSelection);
                            }}
                        >
                            <FormattedMessage
                                defaultMessage="Select again"
                                description="Select again bulk edit button label"
                            />
                        </Button>
                    </Stack>
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
};
