import { Divider, List } from "@mui/material";
import { useNavigate } from "react-router-dom-v5-compat";

import { TableViews_SupplierFragment } from "@/gql/graphql";
import { formatCompactCurrency } from "@/lib/format";
import { track } from "@/lib/track";
import { useCurrencyCode } from "@/providers/CurrencyProvider";

import { EmptyListState } from "./EmptyListState";
import { ListContentContainer } from "./ListContentContainer";
import { ListRow } from "./ListRow";

interface HighRiskSuppliersListProps {
    topSuppliers?: TableViews_SupplierFragment[];
    topSuppliersLoading: boolean;
}

interface ListSupplier {
    id: string;
    name: string;
    spend: number;
}

export const HighRiskSuppliersList: React.FC<HighRiskSuppliersListProps> = ({ topSuppliers, topSuppliersLoading }) => {
    const navigate = useNavigate();
    const currency = useCurrencyCode();
    const highRiskSuppliers: ListSupplier[] =
        topSuppliers
            ?.filter((supplier) => supplier.risk?.social === "HIGH")
            .map((supplier) => ({
                id: supplier.id,
                name: supplier.name,
                spend: supplier.spend.lastAvailableYear?.value ?? supplier.spend.total,
            }))
            .sort((a, b) => b.spend - a.spend)
            .slice(0, 10) ?? [];

    const handleOnNavigate = (supplierId: string) => {
        track("Social Risk Domain Page: Navigate Supplier Page", { Widget: "HighRiskSuppliersList" });
        navigate(`supplier-profile/${supplierId}/overview/`);
    };
    return (
        <List>
            <Divider />
            <ListContentContainer loading={topSuppliersLoading}>
                {topSuppliers === undefined ? (
                    <EmptyListState />
                ) : (
                    highRiskSuppliers.map((supplier) => (
                        <ListRow
                            key={supplier.name}
                            risk="HIGH"
                            name={supplier.name}
                            value={formatCompactCurrency(Number(supplier.spend), currency)}
                            onClick={() => handleOnNavigate(supplier.id)}
                        />
                    ))
                )}
            </ListContentContainer>
        </List>
    );
};
