import { useQuery } from "@apollo/client";
import { ChevronDown } from "@ignite-analytics/icons";
import { formatValue } from "@ignite-analytics/locale";
import { Autocomplete, Box, Chip, FormControl, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { ResponseRateQuestionnaires_QuestionnaireFragment } from "@/gql/graphql";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

graphql(`
    fragment ResponseRateQuestionnaires_Questionnaire on Questionnaire {
        id
        name
        campaigns {
            id
            assessments {
                id
                submittedAt
            }
        }
    }
`);

const getQuestionnaires = graphql(`
    query ResponseRateQuestionnaires_GetQuestionnaires {
        getQuestionnaires {
            questionnaires {
                ...ResponseRateQuestionnaires_Questionnaire
            }
        }
    }
`);

export const ResponseRateQuestionnaires: React.FC = () => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<
        ResponseRateQuestionnaires_QuestionnaireFragment | undefined
    >(undefined);
    const [selectedOption, setSelectedOption] = useState<{ id: string; name: string } | undefined>(undefined);
    const { data: questionnaireData, loading: isQuestionnaireLoading } = useQuery(getQuestionnaires, {
        onCompleted: (data) => {
            setSelectedQuestionnaire(data.getQuestionnaires.questionnaires[0]);
            setSelectedOption({
                id: data.getQuestionnaires.questionnaires[0].id,
                name: data.getQuestionnaires.questionnaires[0].name,
            });
        },
    });
    const questionnaires = questionnaireData?.getQuestionnaires?.questionnaires;

    const options: { id: string; name: string }[] =
        questionnaires
            ?.reduce(
                (prev, item: ResponseRateQuestionnaires_QuestionnaireFragment) => {
                    if (
                        item.campaigns?.length !== 0 ||
                        !item.campaigns?.every((campaign) => campaign.assessments.length === 0)
                    ) {
                        prev = [
                            ...prev,
                            {
                                id: item.id,
                                name: item.name,
                            },
                        ];
                    }
                    return prev;
                },
                [] as { id: string; name: string }[]
            )
            .sort((a, b) => a.name.localeCompare(b.name)) ?? [];

    const selectedResponseRate: number | undefined = useMemo(
        () => calculateResponseRate(selectedQuestionnaire),
        [selectedQuestionnaire]
    );

    return (
        <InsightBox loading={isQuestionnaireLoading}>
            <Stack height="100%">
                <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                    <Typography variant="textSm" color="text.textHelper">
                        <FormattedMessage
                            defaultMessage="Response rate on Questionnaire"
                            description="Response rate on questionnaire header"
                        />
                    </Typography>
                    <Stack
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        justifyContent="flex-start"
                    >
                        {!selectedOption ? (
                            isQuestionnaireLoading ? (
                                <Skeleton width={250} height={80} />
                            ) : (
                                <Typography variant="textSm" color="text.textHelper">
                                    <FormattedMessage
                                        defaultMessage="No questionnaires available"
                                        description="No questionnaires available message"
                                    />
                                </Typography>
                            )
                        ) : (
                            <FormControl fullWidth margin="none" sx={{ width: 250 }}>
                                <Autocomplete
                                    id="questionnaireId"
                                    sx={{
                                        zIndex: 999,
                                    }}
                                    options={options}
                                    popupIcon={<ChevronDown fontSize="small" />}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                "& .MuiOutlinedInput-root": {
                                                    margin: 0,
                                                },
                                                fontWeight: "medium",
                                            }}
                                            size="small"
                                        />
                                    )}
                                    renderOption={(props, option) => {
                                        return (
                                            <Box
                                                component="li"
                                                sx={{
                                                    display: "flex",
                                                    lineHeight: "1.2rem",
                                                    padding: "32px 16px !important",
                                                }}
                                                {...props}
                                            >
                                                {option.name}
                                            </Box>
                                        );
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    value={selectedOption}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={(_, newValue) => {
                                        if (!newValue) {
                                            return;
                                        }
                                        setSelectedQuestionnaire(() =>
                                            questionnaires?.find((questionnaire) => questionnaire.id === newValue.id)
                                        );
                                        setSelectedOption(newValue);
                                    }}
                                    size="small"
                                    fullWidth
                                />
                            </FormControl>
                        )}
                    </Stack>
                </Stack>
                {selectedResponseRate ? (
                    <Stack justifyContent="space-between" height="100%" spacing={2}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="displayMd" fontWeight="medium">{`${formatValue(
                                selectedResponseRate,
                                2
                            )}%`}</Typography>
                            {selectedResponseRate < 50 && (
                                <Chip
                                    color="error"
                                    label={formatMessage({
                                        defaultMessage: "Low response rate",
                                        description: "low response rate on questionnaire chip",
                                    })}
                                />
                            )}
                        </Stack>
                        <ViewButton
                            buttonText={formatMessage({
                                defaultMessage: "View answers",
                                description: "View answers button",
                            })}
                            onClick={() =>
                                selectedQuestionnaire &&
                                navigate(`/assessments/questionnaires/${selectedQuestionnaire.id}/answers`)
                            }
                        />
                    </Stack>
                ) : (
                    <Typography variant="displayMd">- %</Typography>
                )}
            </Stack>
        </InsightBox>
    );
};

function calculateResponseRate(questionnaire: ResponseRateQuestionnaires_QuestionnaireFragment | undefined) {
    const assessments = questionnaire?.campaigns?.flatMap((campaign) => campaign.assessments);
    const submittedAssessments = assessments?.filter((assessment) => assessment.submittedAt) ?? [];
    if (!questionnaire || !assessments) return undefined;
    return (submittedAssessments?.length / assessments?.length) * 100;
}
