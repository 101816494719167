import { Check, Play, X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { MediaPlayer, MediaProvider, Track } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import riskDemoText from "@/assets/videos/socialriskdemo-en-US.vtt";
import riskDemo from "@/assets/videos/socialriskdemo.mp4";
import { useImage } from "@/hooks/useImage";

import { AlertIconWrapper } from "./AlertIcons";

const baseUrl = "https://imagedelivery.net/15ALoYjGmzQG831cZcmjQQ/";
const imageId = "1c223920-e850-4b8e-4dd6-6a0bae0d8500";
const variantName = "public";

export const MissingModuleAccessBanner: React.FC = () => {
    const { formatMessage } = useIntl();
    const [openDialog, setOpenDialog] = useState<"learnMore" | "watchDemo" | null>(null);
    const image = useImage(`${baseUrl}${imageId}/${variantName}`);
    const [messageSent, setMessageSent] = useState(false);
    const [error, setError] = useState(false);
    const [contactMessage, setContactMessage] = useState("");

    const handleGetStartedClick = () => {
        track("Explore Modules: Clicked on supplier management", { page: window.location });
        setOpenDialog("watchDemo");
    };
    const handleClickLearnMore = () => {
        track("Risk overview: Clicked on learn more");
        setOpenDialog("learnMore");
    };

    const handleContactMe = () => {
        track("Risk overview: Clicked contact me");
        if (!process.env.VITE_MODULE_INTEREST_SLACK_WEBHOOK) return;
        fetch(process.env.VITE_MODULE_INTEREST_SLACK_WEBHOOK, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                module: "risk",
                message: contactMessage,
            }),
        })
            .then(() => {
                setMessageSent(true);
            })
            .catch(() => {
                setError(true);
            });
    };

    return (
        <>
            <Card variant="outlined">
                <CardContent sx={{ background: "#F7F7F8" }}>
                    <Stack direction="row" gap={2}>
                        <Stack gap={2} justifyContent="ace-between">
                            <Stack gap={1}>
                                <Box>
                                    <Chip
                                        label={
                                            <Typography variant="textMd" fontWeight={500}>
                                                <FormattedMessage
                                                    defaultMessage="Supplier management"
                                                    description="Chip text for missing module banner"
                                                />
                                            </Typography>
                                        }
                                        color="success"
                                    />
                                </Box>
                                <Typography variant="displayMd" fontWeight={600}>
                                    <FormattedMessage
                                        defaultMessage="Manage your social risk and comply with regulations"
                                        description="Missing module banner header"
                                    />
                                </Typography>
                                <Typography variant="textMd" color="text.textSecondary">
                                    <FormattedMessage
                                        defaultMessage="Manage your suppliers in one place.
                                            Your supplier data will be automatically enriched with risk parameters,
                                            making it easy for you to prioritize which suppliers to follow up, send due
                                            diligence assessments, and comply with regulations like the Transparency Act."
                                        description="Missing module banner text"
                                    />
                                </Typography>
                            </Stack>

                            <Stack direction="row" gap={1} justifyContent="start">
                                <Button startIcon={<Play />} color="secondary" onClick={handleGetStartedClick}>
                                    <FormattedMessage defaultMessage="Watch demo" description="Watch demo button" />
                                </Button>
                                <Button color="primary" onClick={handleClickLearnMore}>
                                    <FormattedMessage defaultMessage="Learn more" description="Learn more button" />
                                </Button>
                            </Stack>
                        </Stack>
                        {image.data ? (
                            <Box sx={{ minWidth: 450, position: "relative", left: 50, maxHeight: 300 }}>
                                <Grid container alignItems="flex-end" justifyContent="flex-end">
                                    <Grid item>
                                        <img src={image.data} alt="Risk module banner" style={{ height: "400px" }} />
                                    </Grid>
                                </Grid>
                            </Box>
                        ) : null}
                    </Stack>
                </CardContent>
            </Card>
            {openDialog === "learnMore" && (
                <Dialog open onClose={() => setOpenDialog(null)} fullWidth maxWidth="xs">
                    <DialogContent sx={{ overflow: "visible" }}>
                        <Stack justifyContent="center" alignItems="center" gap={2}>
                            {!messageSent ? (
                                <>
                                    <Stack alignItems="center" gap={1}>
                                        <Typography variant="displayXs" fontWeight={500}>
                                            <FormattedMessage
                                                defaultMessage="Learn more"
                                                description="Learn more button"
                                            />
                                        </Typography>
                                        <Typography textAlign="center" variant="textSm" color="text.textHelper">
                                            <FormattedMessage
                                                defaultMessage="Schedule a meeting with your Customer Success Manager and see what supplier management can do for you."
                                                description="Schedule meeting button"
                                            />
                                        </Typography>
                                    </Stack>
                                    <TextField
                                        multiline
                                        rows={5}
                                        placeholder={formatMessage({ defaultMessage: "Want to add anything else?" })}
                                        onChange={(e) => setContactMessage(e.target.value)}
                                        sx={{ width: "100%" }}
                                    />
                                    <Button fullWidth onClick={handleContactMe}>
                                        <FormattedMessage defaultMessage="Contact me" />
                                    </Button>
                                    {error && (
                                        <Alert severity="error">
                                            <FormattedMessage
                                                defaultMessage="We were not able to send the message, please try again or contact us by clicking"
                                                description="Contact form error message"
                                            />{" "}
                                            <Link href="mailto:cs@ignite.no" target="_blank" rel="noopener noreferrer">
                                                <FormattedMessage defaultMessage="contact us" />
                                            </Link>
                                        </Alert>
                                    )}
                                </>
                            ) : (
                                <Stack alignItems="center" gap={2} width="100%">
                                    <AlertIconWrapper color="success">
                                        <Check color="primary" />
                                    </AlertIconWrapper>
                                    <Stack gap={0.5} alignItems="center">
                                        <Typography variant="textLg" fontWeight={500}>
                                            <FormattedMessage defaultMessage="Thank you" description="Thank you" />
                                        </Typography>
                                        <Typography variant="textSm" color="text.textHelper">
                                            <FormattedMessage
                                                defaultMessage="We'll be in touch shortly"
                                                description="Thank you helper"
                                            />
                                        </Typography>
                                    </Stack>
                                    <Button
                                        fullWidth
                                        onClick={() => {
                                            setOpenDialog(null);
                                            setMessageSent(false);
                                        }}
                                    >
                                        <FormattedMessage
                                            defaultMessage="Close"
                                            description="Model close button text"
                                        />
                                    </Button>
                                </Stack>
                            )}
                        </Stack>
                    </DialogContent>
                </Dialog>
            )}

            {openDialog === "watchDemo" && (
                <Dialog open onClose={() => setOpenDialog(null)} fullWidth maxWidth="lg">
                    <DialogTitle>
                        <Stack justifyContent="flex-end" alignItems="center" direction="row">
                            <Button
                                onClick={() => {
                                    setOpenDialog(null);
                                }}
                                size="2xsmall"
                                color="ghostGray"
                                endIcon={<X fontSize="inherit" />}
                            >
                                <FormattedMessage defaultMessage="Close" description="Model close button text" />
                            </Button>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <Stack justifyContent="center" alignItems="center" gap={2} p={2}>
                            <MediaPlayer title="Social risk overview demo" src={riskDemo}>
                                <MediaProvider />
                                <DefaultVideoLayout icons={defaultLayoutIcons} />
                                <Track src={riskDemoText} kind="subtitles" label="English" lang="en-US" default />
                            </MediaPlayer>
                        </Stack>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
