import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Meter, Search } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Divider, IconButton, Stack, TextField, Tooltip } from "@mui/material";
import {
    gridRowSelectionStateSelector,
    GridToolbarContainer,
    useGridApiContext,
    useGridSelector,
} from "@mui/x-data-grid-pro";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ActionBar_SupplierTableColumnFragment } from "@/gql/graphql";

import SendCampaign from "../../../Actions/SendCampaign";
import {
    DeepStreamCredentials,
    useSubscriptionsApiFetch,
} from "../../../Actions/StartRfx/hooks/useSubscriptionsApiFetch";
import { StartRfx } from "../../../Actions/StartRfx/StartRfx";
import { TableActions } from "../../../constants";
import { InlineFilter } from "../../../InlineFilter";
import { FilterInput } from "../../../InlineFilter/types";
import { NoPermissionTooltip } from "../../../NoPermissionTooltip";
import { ActionBarProps } from "../../hooks/useTableData/useTableData";
import { DeleteSuppliers } from "../DeleteSuppliers";

import { BulkActionsButton } from "./BulkActionsButton/index";
import { GridToolbarTotalItemsContainer } from "./GridToolbarTotalItemsContainer";
import { TablePreferences } from "./TablePreferences";
import { TableConfigsMenuButton, TableConfigsMenuButtonProps } from "./ViewsButton/TableConfigsMenuButton";

export interface SupplierDataGridToolbarProps extends ActionBarProps {
    isEditor: boolean;
    columns: ActionBar_SupplierTableColumnFragment[];
    setOpenModal: (action: TableActions | false) => void;
    searchTerm: string;
    setSearchTerm: (searchTerm: string) => void;
    tableConfigMenuProps: TableConfigsMenuButtonProps;
    searchFieldFocused: boolean;
    handleSearchFieldFocus: () => void;
    handleSearchFieldBlur: () => void;
}

declare module "@mui/x-data-grid-pro" {
    interface ToolbarPropsOverrides extends SupplierDataGridToolbarProps {}
}

export const SupplierDataGridToolbar: React.FC<SupplierDataGridToolbarProps> = ({
    isEditor,
    columns,
    filter,
    onFilterChange,
    setOpenModal,
    searchTerm,
    setSearchTerm,
    tableConfigMenuProps,
    searchFieldFocused,
    handleSearchFieldFocus,
    handleSearchFieldBlur,
    onNameSearchChange,
    onDeleteSuppliers,
    density,
    onDensityChange,
    onToggleSuppliersDataPoll,
    suppliersDataPollActive,
    refetchSuppliers,
}) => {
    const apiRef = useGridApiContext();
    const { formatMessage } = useIntl();
    const handleSearch = useMemo(
        () =>
            debounce(function handleSearch(searchTerm: string) {
                onNameSearchChange(searchTerm);
                track("Supplier Table: Searched");
            }, 500),
        [onNameSearchChange]
    );

    const deleteSupplierEnabled = useFeatureToggle("delete-supplier", false);
    const deepStreamCredentials = useSubscriptionsApiFetch<DeepStreamCredentials>("settings").data;

    const bulkEditEnabled = useFeatureToggle("supplier-table-bulk-edit", false);

    const selectedSuppliers = useGridSelector(apiRef, gridRowSelectionStateSelector);
    const selectedSuppliersStr = React.useMemo(() => selectedSuppliers.map((id) => id.toString()), [selectedSuppliers]);

    const textFieldRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (searchFieldFocused) {
            textFieldRef.current?.focus();
        } else {
            textFieldRef.current?.blur();
        }
    }, [searchFieldFocused]);

    return (
        <GridToolbarContainer>
            <Stack p={2} direction="row" width="100%" justifyContent="space-between" alignItems="center">
                <Stack direction="row" columnGap={1} width="50%" alignItems="center">
                    <GridToolbarTotalItemsContainer />
                    <Divider variant="fullWidth" orientation="vertical" flexItem />
                    <TextField
                        data-testid="list-search-field"
                        data-form-type="other"
                        data-lpignore="true"
                        placeholder={formatMessage({
                            defaultMessage: "Supplier name",
                            description: "Placeholder for supplier name search",
                        })}
                        size="medium"
                        inputRef={textFieldRef}
                        onFocus={handleSearchFieldFocus}
                        onBlur={handleSearchFieldBlur}
                        value={searchTerm}
                        onChange={(e) => {
                            const value = e.target.value;
                            setSearchTerm(value);
                            handleSearch(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: <Search fontSize="medium" color="tertiary" sx={{ mr: 1 }} />,
                        }}
                    />
                    <InlineFilter
                        columns={columns}
                        setFilterInput={(filterInput: FilterInput[]) =>
                            onFilterChange(apiRef?.current.exportState() ?? {}, filterInput)
                        }
                        filterInput={filter}
                    />
                </Stack>
                <Stack direction="row" columnGap={1} alignItems="center">
                    {bulkEditEnabled && (
                        <BulkActionsButton
                            isEditor={isEditor}
                            selectedSuppliers={selectedSuppliersStr}
                            columns={columns}
                            onSelect={apiRef.current.setRowSelectionModel}
                            refetchSuppliers={refetchSuppliers}
                        />
                    )}
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Tooltip
                            title={
                                <FormattedMessage
                                    defaultMessage="Social risk evaluation"
                                    description="Social risk evaluation"
                                />
                            }
                            placement="top"
                        >
                            <IconButton
                                color="secondary"
                                onClick={() => {
                                    setOpenModal("social-risk-evaluation");
                                }}
                                size="small"
                                disabled={!isEditor}
                            >
                                <Meter fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </NoPermissionTooltip>
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <SendCampaign isEditor={isEditor} supplierIds={selectedSuppliers.map((id) => id.toString())} />
                    </NoPermissionTooltip>
                    {deleteSupplierEnabled && isEditor && (
                        <DeleteSuppliers selectedSuppliers={selectedSuppliers} onDelete={onDeleteSuppliers} />
                    )}
                    {deepStreamCredentials?.deepstreamId && (
                        <StartRfx
                            deepStreamId={deepStreamCredentials.deepstreamId}
                            supplierIds={selectedSuppliers.map((id) => id.toString())}
                        />
                    )}
                    <Divider variant="fullWidth" orientation="vertical" flexItem />
                    <TableConfigsMenuButton {...tableConfigMenuProps} />
                    <TablePreferences
                        apiRef={apiRef}
                        density={density}
                        onDensityChange={onDensityChange}
                        onToggleSuppliersDataPoll={onToggleSuppliersDataPoll}
                        suppliersDataPollActive={suppliersDataPollActive}
                    />
                </Stack>
            </Stack>
        </GridToolbarContainer>
    );
};
