import { AlertTitle, Container, ContainerProps, Alert as MuiAlert, Snackbar, styled } from "@mui/material";
import React from "react";

import { useAlert } from "@/providers/Alerts";

const Alert: React.FC = () => {
    const { alert, onClose } = useAlert();

    return (
        <Snackbar
            key={alert?.timestamp}
            open={Boolean(alert?.message)}
            autoHideDuration={5000}
            onClose={onClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            {...alert?.SnackbarProps}
        >
            <MuiAlert severity={alert?.message.severity} title={alert?.message.title} {...alert?.AlertProps}>
                {alert?.message.title && <AlertTitle>{alert.message.title}</AlertTitle>}
                {alert?.message.value}
            </MuiAlert>
        </Snackbar>
    );
};

const AppLayoutContainer = styled(Container)(({ theme }) => ({
    "*:where(html:not([data-new-layout])) &": {
        height: "calc(100vh - 64px)",
        maxHeight: "calc(100vh - 64px)",
        display: "flex",
        flexDirection: "column",
        overflowY: "scroll",
        padding: theme.spacing(0, 3, 6.5, 3),
    },
    "*:where(html[data-new-layout]) &": {
        flex: "1 1 calc(100vh - var(--appbar-height))",
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
}));

/**
 * App Layout should never grow beyond the size of the screen, except for special cases where the screen is extremely small.
 * It should automatically fill, but never exceed, the available height. The parent element is a flex container.
 */
const AppLayout: React.FC<ContainerProps> = (props) => {
    return (
        <>
            <AppLayoutContainer disableGutters={false} maxWidth={false} {...props} />
            <Alert />
        </>
    );
};

export { AppLayout };
