import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import React from "react";

import { graphql } from "@/gql";

import { Context } from "./context";

type Props = {
    children?: React.ReactNode;
};

const getCurrentCustomerQuery = graphql(`
    query getCurrentCustomer {
        getCurrentCustomer {
            currency
        }
    }
`);

export const CurrencyProvider: React.FC<Props> = ({ children }) => {
    const { data } = useQuery(getCurrentCustomerQuery, {
        onError: (error) => {
            Sentry.captureException(error, {
                tags: { app: "supplier-profile-app", message: "Failed to get current customer" },
            });
        },
    });
    const currency = data?.getCurrentCustomer.currency;
    const value = React.useMemo(
        () => ({
            currency,
        }),
        [currency]
    );
    return <Context.Provider value={value}>{children}</Context.Provider>;
};
