import { getLocale } from "@ignite-analytics/locale";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { NACEOptions } from "./NaceOptions";

export interface RiskOption {
    code: string;
    name_no: string;
    name_en: string;
    score: string | null;
}

export const getLocalName = (option: RiskOption | null) => {
    if (!option) return "";

    if (getLocale() === "nb-NO") {
        return option.name_no;
    }
    return option.name_en;
};

const getLabel = (option: RiskOption | null) => {
    if (!option) return "";
    return `${option.code}: ${getLocalName(option)}`;
};

interface IndustryAutocompleteProps {
    onChange: (newValue: string | null) => void;
}

export const IndustryAutocomplete: React.FC<IndustryAutocompleteProps> = ({ onChange }) => {
    const options = NACEOptions;
    const { formatMessage } = useIntl();

    const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: RiskOption | null) => {
        onChange(newValue?.code ?? null);
    };

    return (
        <Autocomplete
            size="small"
            sx={{ minWidth: 300 }}
            options={options}
            fullWidth
            getOptionLabel={getLabel}
            getOptionKey={(option) => option.code}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option) => (
                <Stack component="li" {...props} direction="row" spacing={1}>
                    <Typography variant="textSm">{getLabel(option)}</Typography>
                </Stack>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    aria-label={formatMessage({
                        defaultMessage: "Select a new industry",
                        description: "ARIA label for industry select in bulk edit",
                    })}
                    placeholder={formatMessage({
                        defaultMessage: "Select a new industry",
                        description: "Placeholder for industry select in bulk edit",
                    })}
                />
            )}
            onKeyDown={(event) => {
                if (event.key === "Escape") {
                    // Allow escape to close the autocomplete to return back to the menu
                    return;
                }
                // Don't propagate the event to the menu -> focuses a menu item starting with that letter
                event.stopPropagation();
            }}
            onChange={handleChange}
        />
    );
};
