import { formatValue } from "@ignite-analytics/locale";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

export const TotalHighRiskSuppliers: React.FC = () => {
    const totalHighRiskSuppliers = 241;
    const totalSuppliers = 1502;
    const { formatMessage } = useIntl();
    const percentageHighRisk =
        totalHighRiskSuppliers &&
        totalSuppliers &&
        formatValue((totalHighRiskSuppliers / totalSuppliers) * 100, 2, undefined, true);

    return (
        <InsightBox>
            <Stack justifyContent="space-between" height="100%">
                <Stack spacing={1.5}>
                    <Typography variant="textSm" color="text.textHelper">
                        <FormattedMessage
                            defaultMessage="Suppliers with high social risk"
                            description="Suppliers with high risk header"
                        />
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="displayMd" fontWeight="medium">
                            {totalHighRiskSuppliers}
                        </Typography>
                        <Box>
                            <Chip color="primary" label={`${percentageHighRisk ?? ""}% of all suppliers`} />
                        </Box>
                    </Stack>
                </Stack>
                <ViewButton
                    buttonText={formatMessage({
                        defaultMessage: "View Suppliers",
                        description: "View suppliers button",
                    })}
                    onClick={() => {}}
                />
            </Stack>
        </InsightBox>
    );
};
