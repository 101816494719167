import { useQuery } from "@apollo/client";
import { formatValue } from "@ignite-analytics/locale";
import { LinearProgress, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import WarningIcon from "@/assets/WarningIcon.svg?react";
import { graphql } from "@/gql";
import { GetRiskColumnConfigResponse } from "@/gql/graphql";

import { FilterInput } from "../../../InlineFilter/types";

import { InsightBox } from "./InsightBox";
import { ViewButton } from "./ViewButton";

const getHighRiskSpendShare = graphql(`
    query HighRiskSpendShare_GetHighRiskSpendShare {
        getTotalSocialRisk {
            highRiskShare
        }
    }
`);

interface HighRiskSpendShareProps {
    riskColumnConfig?: Omit<GetRiskColumnConfigResponse, "id">;
    spendColumnIds: string[];
    lastSpendYearColumnId: string;
    onNavigate: (
        filter: FilterInput[],
        visibleColumns: string[],
        widgetName: string,
        sorts?: { columnId: string; order: "desc" | "asc" }[]
    ) => void;
}
export const HighRiskSpendShare: React.FC<HighRiskSpendShareProps> = ({
    riskColumnConfig,
    spendColumnIds,
    onNavigate,
    lastSpendYearColumnId,
}) => {
    const { formatMessage } = useIntl();
    const { data: riskSpendShareData, loading: dataLoading } = useQuery(getHighRiskSpendShare, {
        skip: !lastSpendYearColumnId || spendColumnIds.length === 0,
    });

    const handleOnClick = () => {
        if (!lastSpendYearColumnId || !riskColumnConfig) return;
        const filter: FilterInput[] = [
            {
                type: "include",
                column_id: riskColumnConfig.socialRiskColumn,
                include_exclude_values: ["high"],
                min_range: null,
                max_range: null,
                min_date: null,
                max_date: null,
                include_blanks: false,
            },
        ];
        onNavigate(filter, [...Object.values(riskColumnConfig), ...spendColumnIds], "HighRiskSuppliersSpendShare", [
            {
                columnId: lastSpendYearColumnId,
                order: "desc",
            },
        ]);
    };

    const highRiskShare = (riskSpendShareData?.getTotalSocialRisk.highRiskShare ?? 0) * 100;
    return (
        <InsightBox loading={dataLoading}>
            <Stack justifyContent="space-between" height="100%">
                <Stack spacing={1.5}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="textSm" color="text.textHelper">
                            <FormattedMessage
                                defaultMessage="Spend with high social risk suppliers"
                                description="spend with high risk suppliers header"
                            />
                        </Typography>
                        {!spendColumnIds && <WarningIcon />}
                    </Stack>
                    {spendColumnIds ? (
                        <Stack spacing={1}>
                            <Typography variant="displayMd" fontWeight="medium">{`${formatValue(
                                highRiskShare,
                                2,
                                undefined,
                                true
                            )}%`}</Typography>
                            <LinearProgress
                                variant="determinate"
                                value={highRiskShare}
                                color={highRiskShare < 33 ? "success" : highRiskShare < 66 ? "warning" : "error"}
                                sx={{
                                    borderRadius: 4,
                                    height: 6,
                                    backgroundColor: (theme) => theme.palette.background.variant,
                                }}
                            />
                        </Stack>
                    ) : (
                        <Stack height="100%" maxWidth="241px" justifyContent="center">
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="We need to know which data you want us to use to craft this insight."
                                    description="Missing spend data message"
                                />
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                {lastSpendYearColumnId && (
                    <ViewButton
                        buttonText={formatMessage({
                            defaultMessage: "View Suppliers",
                            description: "View suppliers button",
                        })}
                        onClick={handleOnClick}
                    />
                )}
            </Stack>
        </InsightBox>
    );
};
