import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { useRef, useState, useEffect } from "react";

const isEllipsisActive = (elementRef: React.RefObject<HTMLDivElement>) => {
    if (!elementRef?.current?.scrollWidth || !elementRef?.current?.clientWidth) {
        return false;
    }
    return elementRef.current.scrollWidth > elementRef.current.clientWidth;
};

interface EllipticTypographyProps extends TypographyProps {
    value: string;
}

// This component is used to display a tooltip when the text is too long to fit in the container
export const EllipticTypography: React.FC<EllipticTypographyProps> = ({ value, ...props }) => {
    const typographyRef = useRef<HTMLDivElement>(null);
    const [ellipsisActive, setEllipsisActive] = useState(false);

    useEffect(() => {
        setEllipsisActive(isEllipsisActive(typographyRef));
    }, [value]);

    return (
        <Tooltip
            disableHoverListener={!ellipsisActive}
            disableFocusListener={!ellipsisActive}
            disableTouchListener={!ellipsisActive}
            title={<Typography {...props}>{value}</Typography>}
        >
            <Typography {...props} textOverflow="ellipsis" noWrap ref={typographyRef}>
                {value}
            </Typography>
        </Tooltip>
    );
};
