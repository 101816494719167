import { useQuery } from "@apollo/client";
import { getLocale } from "@ignite-analytics/locale";
import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import { orderBy } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { SupplierCountryLanguage } from "@/gql/graphql";

interface Country {
    id: string;
    iso2Code: string;
    name: string;
}

interface CountrySelectProps {
    onChange: (countryId: string | null) => void;
}

export function getCountryLanguage(): SupplierCountryLanguage {
    const locale = getLocale();
    switch (locale) {
        case "en-US":
            return "enUS";
        case "nb-NO":
            return "nbNO";
    }
}

export const CountrySelect: React.FC<CountrySelectProps> = ({ onChange }) => {
    const handleChange = (_event: React.SyntheticEvent<Element, Event>, newValue: Country | null) => {
        onChange(newValue?.id ?? null);
    };

    const { data } = useQuery(
        graphql(`
            query CountrySelect_getCountries($language: SupplierCountryLanguage) {
                getCountries {
                    countries {
                        id
                        iso2Code
                        name(language: $language)
                    }
                }
            }
        `),
        {
            variables: {
                language: getCountryLanguage(),
            },
        }
    );
    const countries = orderBy(data?.getCountries.countries ?? [], ["name"], ["asc"]);

    const { formatMessage } = useIntl();

    return (
        <Autocomplete
            size="small"
            sx={{ minWidth: 200 }}
            options={countries}
            fullWidth
            getOptionLabel={(country) => `${country.iso2Code}: ${country.name}`}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Stack component="li" {...props} direction="row" spacing={1} fontSize="0.5rem">
                    <Typography variant="textSm">
                        {option.id}: {option.name}
                    </Typography>
                </Stack>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    aria-label={formatMessage({
                        defaultMessage: "Select a new country",
                        description: "ARIA label for country select in bulk edit",
                    })}
                    placeholder={formatMessage({
                        defaultMessage: "Select a new country",
                        description: "Placeholder for country select in bulk edit",
                    })}
                />
            )}
            onKeyDown={(event) => {
                if (event.key === "Escape") {
                    // Allow escape to close the autocomplete to return back to the menu
                    return;
                }
                // Don't propagate the event to the menu -> focuses a menu item starting with that letter
                event.stopPropagation();
            }}
            onChange={(e, val) => handleChange(e, val)}
        />
    );
};
