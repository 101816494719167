import { Plus } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { TableActions } from "../constants";
import { NoPermissionTooltip } from "../NoPermissionTooltip";

interface SupplierTableHeaderProps {
    isOnboarding: boolean;
    isEditor: boolean;
    setOpenModal: (action: TableActions | false) => void;
    hasSelectedSuppliers: boolean;
    onboardingEnabled: boolean;
}

export const SupplierTableHeader: React.FC<SupplierTableHeaderProps> = ({
    isOnboarding,
    isEditor,
    setOpenModal,
    hasSelectedSuppliers,
    onboardingEnabled,
}) => {
    const navigate = useNavigate();

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2} paddingTop={3}>
            <Typography variant="displayXs" fontWeight={(theme) => theme.typography.fontWeightBold} paddingTop={0.5}>
                {isOnboarding ? (
                    <FormattedMessage defaultMessage="Onboarding" description="Onboarding overview page header" />
                ) : (
                    <FormattedMessage defaultMessage="Supplier list" description="Supplier list page header" />
                )}
            </Typography>
            <Stack direction="row" spacing={1}>
                {!isOnboarding && onboardingEnabled && (
                    <Button
                        onClick={() => {
                            track("Supplier Table: Clicked Onboard Existing Suppliers");
                            setOpenModal("onboard-existing-suppliers");
                        }}
                        size="small"
                        disabled={!isEditor || !hasSelectedSuppliers}
                        color="secondary"
                    >
                        <FormattedMessage
                            defaultMessage="Onboard existing suppliers"
                            description="Onboard existing suppliers button"
                        />
                    </Button>
                )}
                {isOnboarding && onboardingEnabled && (
                    <Button
                        onClick={() => {
                            track("Onboarding: Clicked Onboard Existing Suppliers");
                            navigate("onboarding/existing");
                        }}
                        size="small"
                        disabled={!isEditor}
                        color="secondary"
                    >
                        <FormattedMessage
                            defaultMessage="Onboard existing suppliers"
                            description="Onboard existing suppliers button"
                        />
                    </Button>
                )}
                {onboardingEnabled ? (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button
                            startIcon={<Plus fontSize="small" />}
                            onClick={() => {
                                track("Supplier Table: Clicked Start Onboarding");
                                navigate("onboarding/new");
                            }}
                            size="small"
                            disabled={!isEditor}
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Onboard supplier" description="Onboard supplier button" />
                        </Button>
                    </NoPermissionTooltip>
                ) : (
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button
                            startIcon={<Plus fontSize="small" />}
                            onClick={() => {
                                track("Supplier Table: Clicked Add Supplier");
                                setOpenModal("add-supplier");
                            }}
                            size="small"
                            disabled={!isEditor}
                            color="primary"
                        >
                            <FormattedMessage defaultMessage="Add Supplier" description="Add supplier button" />
                        </Button>
                    </NoPermissionTooltip>
                )}
            </Stack>
        </Stack>
    );
};
