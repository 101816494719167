import { useQuery } from "@apollo/client";
import { CircleSolid, InformationCircle } from "@ignite-analytics/icons";
import { Box, Chip, Paper, Popper, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { CodeOfConduct } from "@/gql/graphql";

import { ExpirationChip } from "../../ExpirationChip";

interface CodeOfConductsFieldProps {
    codeOfConducts: CodeOfConduct[];
}

const getUsersDocument = graphql(`
    query SupplierInfo_GetUser($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                id
                fullName
            }
        }
    }
`);

export const CodeOfConductsField = ({ codeOfConducts }: CodeOfConductsFieldProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    const mostRecentCoc = [...codeOfConducts].sort(
        (a, b) => new Date(b.uploadedAt).getTime() - new Date(a.uploadedAt).getTime()
    )[0];

    const { data: usersData } = useQuery(getUsersDocument, {
        variables: { input: {} },
        onError: () => {},
    });

    const usersResult = usersData?.getUsers.result;

    return (
        <Chip
            variant="status"
            icon={<CircleSolid />}
            color={codeOfConducts.length > 0 ? "success" : "neutral"}
            iconSize="small"
            size="small"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            label={
                <Stack direction="row" spacing={0.75}>
                    <Typography variant="textSm">
                        {codeOfConducts.length > 0 ? (
                            <FormattedMessage defaultMessage="Uploaded" />
                        ) : (
                            <FormattedMessage defaultMessage="Missing" />
                        )}
                    </Typography>
                    {codeOfConducts.length > 0 && (
                        <>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <InformationCircle style={{ width: "16px", height: "16px" }} />
                            </Box>

                            <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1500 }}>
                                <Paper sx={{ p: 2 }}>
                                    <Stack spacing={2}>
                                        <Stack spacing={1}>
                                            <Stack direction="row" spacing={0.5}>
                                                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                                    <FormattedMessage defaultMessage="Uploaded:" />
                                                </Typography>
                                                <Typography variant="textSm" fontWeight={400} color="textTextHelper">
                                                    {new Date(mostRecentCoc.uploadedAt).toLocaleDateString("en-GB", {
                                                        day: "2-digit",
                                                        month: "short",
                                                        year: "numeric",
                                                    })}
                                                    {mostRecentCoc.uploadedBy &&
                                                        usersResult?.find(
                                                            (user) => user.id === mostRecentCoc.uploadedBy
                                                        )?.fullName &&
                                                        `, ${
                                                            usersResult.find(
                                                                (user) => user.id === mostRecentCoc.uploadedBy
                                                            )?.fullName
                                                        }`}
                                                </Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={5} justifyContent="space-between">
                                                {mostRecentCoc.originalFilename && (
                                                    <Stack spacing={0.5}>
                                                        <Typography variant="textMd" fontWeight={500}>
                                                            {decodeURIComponent(mostRecentCoc.originalFilename)}
                                                        </Typography>
                                                    </Stack>
                                                )}
                                                {mostRecentCoc.expiresAt && (
                                                    <Stack spacing={0.5} display="inline-block">
                                                        <ExpirationChip expiryDate={mostRecentCoc.expiresAt} />
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Paper>
                            </Popper>
                        </>
                    )}
                </Stack>
            }
        />
    );
};
