import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Collapse,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { CampaignType, NewCampaignTab_SupplierFragmentDoc, NewCampaignTab_QuestionnaireFragment } from "@/gql/graphql";
import { useAlert } from "@/providers/Alerts";

graphql(`
    fragment NewCampaignTab_Questionnaire on Questionnaire {
        id
        name
        status
    }

    fragment NewCampaignTab_Supplier on Supplier {
        id
        name
    }
`);

const getQuestionnairesDocument = graphql(`
    query NewCampaignTab_GetQuestionnaires {
        getQuestionnaires {
            questionnaires {
                ...NewCampaignTab_Questionnaire
            }
        }
    }
`);

const createExternalCampaignDocument = graphql(`
    mutation CreateCampaignModal_CreateExternalCampaign($input: CreateExternalCampaignInput!) {
        createExternalCampaign(input: $input) {
            campaign {
                id
            }
        }
    }
`);

type Props = {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    supplierIds: string[];
};

function isNotNull<T>(value: T): value is NonNullable<T> {
    return value !== null;
}

export const CreateNewCampaignTab: React.FC<Props> = ({ setStep, supplierIds }) => {
    const [campaignType, setCampaignType] = useState<CampaignType>("external");
    const [campaignName, setCampaignName] = useState("");
    const [dueDate, setDueDate] = useState<dayjs.Dayjs | null>(null);
    const [error, setError] = useState(false);

    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<NewCampaignTab_QuestionnaireFragment>();

    const { data } = useQuery(getQuestionnairesDocument, {
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get questionnaires",
                    description: "Alert message when questionnaires fail to load",
                }),
                severity: "error",
            });
        },
    });
    const allQuestionnaires = data?.getQuestionnaires.questionnaires;

    const navigate = useNavigate();
    const location = useLocation();
    const onV2 = location.pathname.includes("/v2/");
    const [createExternalCampaign] = useMutation(createExternalCampaignDocument, {
        onCompleted: (res) => {
            navigate(`${onV2 ? "/v2" : ""}/assessments/campaigns/${res.createExternalCampaign.campaign.id}/`);
        },
        onError: () =>
            alertUser({
                value: formatMessage({
                    defaultMessage: "An error occurred when creating the campaign.",
                    description: "Alert message when creating a campaign fails",
                }),
                severity: "error",
            }),
    });

    const createCampaign = () => {
        if (!campaignName || !campaignType || !dueDate || !selectedQuestionnaire || !selectedQuestionnaire.id) {
            setError(true);
        } else {
            setError(false);
            track("Supplier Table: Clicked Create Campaign", {
                numberOfSuppliers: supplierIds?.length,
                questionnaireId: selectedQuestionnaire.id,
                campaignType,
            });
            createExternalCampaign({
                variables: {
                    input: {
                        campaign: {
                            name: campaignName,
                            type: campaignType,
                            dueDate: dueDate.toISOString(),
                            questionnaireId: selectedQuestionnaire.id,
                        },
                        supplierIds: supplierIds,
                    },
                },
            });
        }
    };

    //Still need this for internal campaigns
    const client = useApolloClient();
    const selectedSuppliers = useMemo(
        () =>
            supplierIds
                .map((id) =>
                    client.readFragment({
                        id: `Supplier:${id}`,
                        fragment: NewCampaignTab_SupplierFragmentDoc,
                        fragmentName: "NewCampaignTab_Supplier",
                    })
                )
                .filter(isNotNull),
        [supplierIds, client]
    );

    const handleCompleted = () => {
        if (campaignType === "external") {
            createCampaign();
            return;
        }
        //In case of internal campaign
        if (selectedSuppliers && selectedSuppliers?.length > 0) {
            const out = JSON.stringify(selectedSuppliers);
            sessionStorage.setItem("selectedSuppliers", out);
            navigate(`${onV2 ? "/v2" : ""}/assessments/campaigns/create/internal/?fromSupplierTable=true`);
        }
    };

    return (
        <>
            <DialogTitle mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <FormattedMessage defaultMessage="Create new campaign" description="Create new campaign title" />
                </Stack>
            </DialogTitle>
            <DialogContent>
                <RadioGroup
                    value={campaignType}
                    onChange={(event) => {
                        setCampaignType(event.target.value as CampaignType);
                    }}
                >
                    <Stack spacing={2}>
                        <FormControlLabel
                            value="external"
                            control={<Radio />}
                            label={
                                <Stack>
                                    <Typography variant="textSm">
                                        <FormattedMessage defaultMessage="External" description="External option" />
                                    </Typography>
                                    <Typography variant="textSm" color="text.textHelper">
                                        <FormattedMessage
                                            defaultMessage="Invite your suppliers to perform a self-assessment"
                                            description="Invite your suppliers to perform a self-assessment"
                                        />
                                    </Typography>
                                </Stack>
                            }
                            sx={{ alignItems: "flex-start" }}
                        />

                        <FormControlLabel
                            value="internal"
                            control={<Radio />}
                            label={
                                <Stack>
                                    <Typography variant="textSm">
                                        <FormattedMessage defaultMessage="Internal" description="Internal option" />
                                    </Typography>
                                    <Typography variant="textSm" color="text.textHelper">
                                        <FormattedMessage
                                            defaultMessage="Invite your colleagues to evaluate your suppliers"
                                            description="Invite your colleagues to evaluate your suppliers"
                                        />
                                    </Typography>
                                </Stack>
                            }
                            sx={{ alignItems: "flex-start" }}
                        />
                    </Stack>
                </RadioGroup>

                <Collapse in={campaignType === "external"}>
                    <Divider sx={{ my: 2 }} />
                    <Stack spacing={2.5}>
                        <TextField
                            label={
                                <FormattedMessage defaultMessage="Campaign name" description="Campaign name input" />
                            }
                            fullWidth
                            size="small"
                            value={campaignName}
                            onChange={(event) => setCampaignName(event.target.value as string)}
                            error={error && !campaignName}
                            placeholder={formatMessage({
                                defaultMessage: "This is your campaign's display name",
                                description: "Campaign creation name helper text",
                            })}
                            required
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={<FormattedMessage defaultMessage="Due date" description="Due date input" />}
                                timezone="UTC"
                                value={dueDate}
                                onChange={(date) => {
                                    if (date) {
                                        setDueDate(date);
                                    }
                                }}
                                format="DD-MM-YYYY"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        required: true,
                                        fullWidth: true,
                                        error: error && !dueDate,
                                        helperText: (
                                            <FormattedMessage
                                                defaultMessage="Latest date when suppliers can reply"
                                                description="Campaign date picker helper text"
                                            />
                                        ),
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        <Divider />
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Questionnaire" description="Select questionnaire title" />
                        </Typography>
                        <FormControl error={error && !selectedQuestionnaire} size="small">
                            <Select
                                value={selectedQuestionnaire?.id || ""}
                                onChange={(event) => {
                                    setSelectedQuestionnaire(
                                        allQuestionnaires?.find(
                                            (questionnaire) => questionnaire.id === event.target.value
                                        )
                                    );
                                }}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    <FormattedMessage
                                        defaultMessage="Select questionnaire"
                                        description="Questionnaire selection placeholder"
                                    />
                                </MenuItem>
                                {allQuestionnaires
                                    ?.filter((questionnaire) => questionnaire.status !== "archived")
                                    ?.map((questionnaire) => (
                                        <MenuItem value={questionnaire.id} key={questionnaire.id}>
                                            {questionnaire.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {error && !selectedQuestionnaire && (
                                <FormHelperText>
                                    <FormattedMessage
                                        defaultMessage="Select questionnaire"
                                        description="Questionnaire selection placeholder"
                                    />
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="text"
                    onClick={() => {
                        setStep(0);
                    }}
                    size="medium"
                    color="secondary"
                >
                    <FormattedMessage defaultMessage="Back" description="Back button" />
                </Button>
                <Button
                    onClick={() => {
                        handleCompleted();
                    }}
                    color="primary"
                >
                    {campaignType === "external" ? (
                        <FormattedMessage defaultMessage="Create" description="Create campaign" />
                    ) : (
                        <FormattedMessage defaultMessage="Next" description="Next button" />
                    )}
                </Button>
            </DialogActions>
        </>
    );
};
