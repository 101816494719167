import { useQuery } from "@apollo/client";
import { Container, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { useGetModuleAccessPermissions } from "@/hooks/useModuleAccess";
import { useSetBreadcrumbs } from "@/hooks/useSetBreadcrumbs";
import { usePermission } from "@/lib/permissions";
import { track } from "@/lib/track";

import { FilterInput } from "../InlineFilter/types";
import { setSocialRiskTableView } from "../MuiDataGridTable/tableUtils";

import { KeyInsightsDemo } from "./Demo/KeyInsightsDemo";
import { TableViewsDemo } from "./Demo/TableViewsDemo";
import { KeyInsights } from "./KeyInsights/KeyInsights";
import { MissingModuleAccessBanner } from "./MissingModuleBanner";
import { NoSpendDataCard } from "./NoSpendDataCard";
import { TableViews } from "./TableViews/TableViews";

const getTopSuppliers = graphql(`
    query OverviewPage_GetTopSuppliers($input: GetTopSuppliersInput!) {
        getTopSuppliers(input: $input) {
            suppliers {
                id
                name
                country
                customFields {
                    fieldId
                    name
                    fieldType
                }
                spend {
                    lastAvailableYear {
                        columnId
                    }
                    lastFiveYears {
                        columnId
                    }
                }
                ...TableViews_Supplier
            }
        }
    }
`);

const getTotalSuppliersHighSocialRisk = graphql(`
    query OverviewPage_GetSupplierHighRisk($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
            }
            total
        }
    }
`);

const getTotalSuppliers = graphql(`
    query OverviewPage_GetSuppliersTotal($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
            }
            total
        }
    }
`);

const getRiskColumnConfig = graphql(`
    query OverviewPage_GetRiskColumnConfig {
        getRiskColumnConfig {
            ...OverView_RiskColumnConfig
        }
    }

    fragment OverView_RiskColumnConfig on GetRiskColumnConfigResponse {
        id
        nameColumn
        countryColumn
        orgNumberColumn
        socialRiskColumn
        industryRiskColumn
        geographyRiskColumn
        naceColumn
        ...TableViews_RiskColumnConfig
    }
`);

const columnsDocument = graphql(`
    query supplierTableColumns {
        getSupplierTableMeta {
            columns {
                id
            }
        }
    }
`);

export const OverviewPage: React.FC = () => {
    useSetBreadcrumbs("socialRisk");
    const navigate = useNavigate();
    const [spendColumnIds, setSpendColumnIds] = useState<string[]>([]);
    const [lastYearSpendColumnId, setLastYearSpendColumnId] = useState<string>("");
    const [totalHighRiskSuppliers, setTotalHighRiskSuppliers] = useState<number | undefined>(undefined);
    const [totalSuppliers, setTotalSuppliers] = useState<number | undefined>(undefined);
    const [noSpendData, setNoSpendData] = useState<boolean>(false);
    const discardedNoSpendData = localStorage.getItem("discardedNoSpendData");
    const isEditor = usePermission({ object: "general", relation: "write" });

    useEffect(() => {
        track("Social Risk Domain Page: Viewed");
    }, []);

    const { data: riskColumnConfig } = useQuery(getRiskColumnConfig);

    const { data: topRiskSuppliers, loading: topRiskSuppliersLoading } = useQuery(getTopSuppliers, {
        variables: {
            input: {
                by: "RISK",
                count: 10,
            },
        },
        onCompleted: (data) => {
            // return if no suppliers
            if (!data.getTopSuppliers.suppliers || data.getTopSuppliers.suppliers.length === 0) {
                !discardedNoSpendData && isEditor && setNoSpendData(true);
                return;
            }

            // collect id of all spend columns
            const hasSpend = data.getTopSuppliers.suppliers[0].spend.lastFiveYears.length > 0;
            if (!hasSpend) {
                !discardedNoSpendData && isEditor && setNoSpendData(true);
                return;
            }
            setSpendColumnIds(data.getTopSuppliers.suppliers[0].spend.lastFiveYears.map((spend) => spend.columnId));
            setLastYearSpendColumnId(data.getTopSuppliers.suppliers[0].spend.lastAvailableYear.columnId);
        },
        onError: () => {
            setNoSpendData(true);
        },
    });
    const socialRiskId = riskColumnConfig?.getRiskColumnConfig.socialRiskColumn;
    const { loading: totalHighRiskSuppliersLoading } = useQuery(getTotalSuppliersHighSocialRisk, {
        variables: {
            input: {
                pageRange: 1,
                pageIndex: 0,
                filterJson: `[{"type":"include","column_id":"${socialRiskId}","include_exclude_values":["high"],"min_range":null,"max_range":null,"min_date":null,"max_date":null,"include_blanks":false}]`,
                nameSearch: "",
            },
        },
        onCompleted: (data) => {
            setTotalHighRiskSuppliers(data.getSuppliers.total);
        },
        skip: !socialRiskId,
    });

    const { loading: totalSuppliersLoading } = useQuery(getTotalSuppliers, {
        variables: {
            input: {
                pageRange: 1,
                pageIndex: 0,
                filterJson: "",
                nameSearch: "",
            },
        },
        onCompleted: (data) => {
            setTotalSuppliers(data.getSuppliers.total);
        },
    });

    const { data: columnsData } = useQuery(columnsDocument);
    const handleOnNavigate = useCallback(
        (
            filter: FilterInput[],
            visibleColumns: string[],
            widgetName: string,
            sorts?: { columnId: string; order: "desc" | "asc" }[]
        ) => {
            const allSupplierTableColumnIds =
                columnsData?.getSupplierTableMeta.columns.map((column) => column.id) ?? [];
            if (allSupplierTableColumnIds.length === 0) return;
            setSocialRiskTableView(filter, visibleColumns, allSupplierTableColumnIds, sorts);
            track("Social Risk Domain Page: Navigate Supplier Table", { Widget: widgetName });
            navigate("/suppliers/overview?fromSocialRiskPage=true");
        },
        [navigate, columnsData?.getSupplierTableMeta.columns]
    );

    const handleOnDiscard = useCallback(() => {
        setNoSpendData(false);
        localStorage.setItem("discardedNoSpendData", "true");
    }, []);

    const { risk: hasRiskModuleAccess } = useGetModuleAccessPermissions(`${process.env.VITE_CUSTOMERS_URL}/modules`);

    return (
        <Container maxWidth="xl">
            <Stack mb={2}>{!hasRiskModuleAccess && <MissingModuleAccessBanner />}</Stack>

            <Stack spacing={2} sx={!hasRiskModuleAccess ? { filter: "blur(2px)", pointerEvents: "none" } : {}}>
                <Stack paddingBottom={1}>
                    <Typography variant="displayXs" component="h1" fontWeight="bold">
                        <FormattedMessage
                            defaultMessage="Social risk overview"
                            description="Risk overview page header"
                        />
                    </Typography>
                </Stack>
                {noSpendData && hasRiskModuleAccess && <NoSpendDataCard onDiscard={handleOnDiscard} />}
                {hasRiskModuleAccess ? (
                    <KeyInsights
                        totalHighRiskSuppliers={totalHighRiskSuppliers}
                        totalSuppliers={totalSuppliers}
                        riskColumnConfig={riskColumnConfig?.getRiskColumnConfig}
                        spendColumnIds={spendColumnIds}
                        lastSpendYearColumnId={lastYearSpendColumnId}
                        onNavigate={handleOnNavigate}
                        totalSuppliersLoading={totalSuppliersLoading}
                        totalHighRiskSuppliersLoading={totalHighRiskSuppliersLoading}
                    />
                ) : (
                    <KeyInsightsDemo />
                )}
                {hasRiskModuleAccess ? (
                    <TableViews
                        topSuppliers={topRiskSuppliers?.getTopSuppliers.suppliers.filter(
                            (sup) => sup.risk?.social === "HIGH"
                        )}
                        topSuppliersLoading={topRiskSuppliersLoading}
                        socialRiskConfig={riskColumnConfig?.getRiskColumnConfig}
                        spendColumnIds={spendColumnIds}
                        lastSpendYearColumnId={lastYearSpendColumnId}
                        onNavigate={handleOnNavigate}
                    />
                ) : (
                    <TableViewsDemo />
                )}
            </Stack>
        </Container>
    );
};
