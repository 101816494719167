import { ApolloError, useMutation } from "@apollo/client";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import { useCallback } from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";

import { TableData } from "./useTableData";

const updateSupplierRowDocument = graphql(`
    mutation processUpdateSupplierRow($input: UpdateSupplierFieldInput!) {
        updateSupplierField(input: $input) {
            supplier {
                ...UseTableData_supplierFields
            }
        }
    }
`);

/**
 * Hook that returns the `processRowUpdate` and `onProcessRowUpdateError` props for the `DataGridPro` component.
 * @see https://mui.com/x/react-data-grid/editing/#server-side-persistence
 *
 * If the `processRowUpdate` function throws an error, the `onProcessRowUpdateError` function will be called.
 * Wrapped in callbacks to prevent React from recreating the functions on render.
 */
export function useProcessRowUpdate(
    refetchSuppliers: () => void,
    isOnboarding?: boolean
): Pick<DataGridProProps<TableData>, "processRowUpdate" | "onProcessRowUpdateError"> {
    const [mutate] = useMutation(updateSupplierRowDocument, {
        onCompleted: () => {
            track("Supplier Table: Edited Value");
            // refetching the suppliers here fixes the issue of suppliers that should have been filtered
            // out still showing up in the table after editing a value
            refetchSuppliers();
        },
    });
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const onProcessRowUpdateError: NonNullable<DataGridProProps<TableData>["onProcessRowUpdateError"]> = useCallback(
        function onProcessRowUpdateError(error: ApolloError) {
            alertUser({
                severity: "error",
                title: formatMessage({
                    defaultMessage: "Failed to update supplier",
                    description: "Alert title to user when updating a cell's value fails.",
                }),
                value: formatMessage(
                    {
                        defaultMessage: "Reason: {error}",
                        description: "Alert description to user when updating a cell's value fails.",
                    },
                    { error: error.message }
                ),
            });
        },
        [alertUser, formatMessage]
    );

    const processRowUpdate: NonNullable<DataGridProProps<TableData>["processRowUpdate"]> = useCallback(
        async function processRowUpdate(newRow: TableData, oldRow: TableData): Promise<TableData> {
            const difference = getRowDifference(newRow, oldRow);
            if (difference) {
                const { fieldId, newValue } = difference;

                const input = {
                    id: newRow.id,
                    fieldId,
                    fieldData: JSON.stringify(newValue),
                };
                const { data, errors } = await mutate({ variables: { input } });
                if (errors) {
                    throw new Error(errors?.[0].message);
                }
                if (isOnboarding) {
                    track("Onboarding: Updated supplier field", {
                        source: "Onboarding list",
                        field: data?.updateSupplierField.supplier.customFields.find((field) => field.id === fieldId)
                            ?.name,
                        value: newValue,
                    });
                }
            }
            return newRow;
        },
        [mutate, isOnboarding]
    );
    return { processRowUpdate, onProcessRowUpdateError };
}

/**
 * Returns the key for the field that should be updated, and its new value. Returns null if the rows are identical.
 */
function getRowDifference(newRow: TableData, oldRow: TableData) {
    for (const [key, value] of Object.entries(newRow)) {
        if (oldRow[key] !== value) return { fieldId: key, newValue: value };
    }
    return null;
}
